import { Input } from '@goodlok/ui'
import {
	createContext,
	DetailedHTMLProps,
	FunctionComponent,
	InputHTMLAttributes,
	ReactNode,
	useCallback,
	useContext,
	useMemo,
} from 'react'
import { useStorageBackedState } from 'use-storage-backed-state'
import styles from './GlobalDateRange.module.sass'

const globalDateRangeContext = createContext<{
	range: {
		start: null | Date
		end: null | Date
	}
	setRange: (start: null | Date, end: null | Date) => void
}>({
	range: {
		start: null,
		end: null,
	},
	setRange: () => {
		throw new Error('Missing context provider.')
	},
})

const dayDateToString = (date: Date) =>
	`${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
		.getUTCDate()
		.toString()
		.padStart(2, '0')}`

/**
 * @param date Is expected to be ****-**-**T00:00:00.000Z
 */
const getDateEnd = (date: Date) => {
	const dateEnd = new Date(date)
	dateEnd.setDate(dateEnd.getUTCDate() + 1) // Plus one day
	dateEnd.setTime(dateEnd.getTime() - 1) // One ms before midnight
	return dateEnd
}

const useRange = (startDate?: Date, endDate?: Date) => {
	const [rangeTimestamps, setRangeTimestamps] = useStorageBackedState(
		() => {
			const start = (() => {
				if (startDate) {
					const date = new Date(startDate)
					const dateFormatted = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
					return dateFormatted
				}
				return null
			})()
			const end = (() => {
				if (endDate) {
					const date = new Date(endDate)
					const dateFormatted = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
					return dateFormatted
				} else if (startDate) {
					const date = new Date()
					const dateFormatted = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
					return getDateEnd(dateFormatted)
				}
				return null
			})()
			return { start: start && start.getTime(), end: end && end.getTime() }
		},
		'date-range',
		'sessionStorage' in globalThis ? sessionStorage : undefined,
	)

	const range = useMemo(
		() => ({
			start: rangeTimestamps.start === null ? null : new Date(rangeTimestamps.start),
			end: rangeTimestamps.end === null ? null : new Date(rangeTimestamps.end),
		}),
		[rangeTimestamps.end, rangeTimestamps.start],
	)
	const setRange = useCallback(
		(start: null | Date, end: null | Date) => {
			setRangeTimestamps({
				start: start && start.getTime(),
				end: end && end.getTime(),
			})
		},
		[setRangeTimestamps],
	)
	return [range, setRange] as const
}

export const GlobalDateRangeProvider: FunctionComponent<{
	//startToday?: boolean
	children: ReactNode
	start?: Date
	end?: Date
}> = ({ start, end, children }) => {
	const [range, setRange] = useRange(start, end)

	const setRangeCallback = useCallback(
		(start: null | Date, end: null | Date) => {
			setRange(start, end)
		},
		[setRange],
	)

	const value = useMemo(() => {
		return {
			range,
			setRange: setRangeCallback,
		}
	}, [range, setRangeCallback])
	return <globalDateRangeContext.Provider value={value}>{children}</globalDateRangeContext.Provider>
}

export const useGlobalDateRangeStart = () => useContext(globalDateRangeContext).range.start
export const useGlobalDateRangeEnd = () => useContext(globalDateRangeContext).range.end
export const useGlobalDateRange = () => useContext(globalDateRangeContext).range
const useSetRange = () => useContext(globalDateRangeContext).setRange

export type GlobalDateRangePickerProps = {
	oneDayOnly?: boolean
	min?: string
	max?: string
} & Pick<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'min' | 'max'>

export const GlobalDateRangePicker: FunctionComponent<GlobalDateRangePickerProps> = ({
	oneDayOnly = false,
	min,
	max,
}) => {
	const startDate = useGlobalDateRangeStart()
	const endDate = useGlobalDateRangeEnd()
	const setRange = useSetRange()

	return (
		<div className={styles.wrapper}>
			<div className={styles.start}>
				<Input
					type="date"
					value={startDate ? dayDateToString(startDate) : ''}
					onChange={event => {
						const value = event.target.value
						const newStartDate = value === '' ? null : new Date(value)

						const newEndDate = (() => {
							if (oneDayOnly === false) {
								return endDate
							}
							return newStartDate === null ? null : getDateEnd(newStartDate)
						})()

						if (newStartDate && endDate && newStartDate > endDate) {
							// Set end date to be the same as start date when start date is after end date
							setRange(newStartDate, getDateEnd(newStartDate))
						} else {
							setRange(newStartDate, newEndDate)
						}
					}}
					min={min}
					max={max}
				/>
			</div>
			{oneDayOnly === false && (
				<div className={styles.end}>
					<Input
						type="date"
						value={endDate ? dayDateToString(endDate) : ''}
						onChange={event => {
							const value = event.target.value
							const newStartDate = startDate
							const newEndDate = value === '' ? null : getDateEnd(new Date(value))

							if (startDate && newEndDate && newEndDate < startDate) {
								// Set start date to be the same as end date when end date is before start date
								setRange(new Date(value), newEndDate)
							} else {
								setRange(newStartDate, newEndDate)
							}
						}}
						min={min}
						max={max}
					/>
				</div>
			)}
		</div>
	)
}
