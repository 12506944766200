import { Card } from './Card'
import styles from './SuccessCard.module.sass'

export type SuccessCardProps = { title: React.ReactNode; notes?: React.ReactNode; actions?: React.ReactNode }

export function SuccessCard(props: SuccessCardProps) {
	return (
		<div className={styles.wrapper} style={{ '--Card-backgroundColor': 'white' } as React.CSSProperties}>
			<Card>
				<div className={styles.cardContent}>
					<div>
						<span className={styles.icon}>
							<svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M28.5 1.49999C43.4117 1.49999 55.5 13.5883 55.5 28.5C55.5 43.4117 43.4117 55.5 28.5 55.5C13.5883 55.5 1.5 43.4117 1.5 28.5C1.5 13.5883 13.5883 1.5 28.5 1.49999Z"
									fill="#DAECD8"
									stroke="#257654"
									stroke-width="3"
								/>
								<path
									d="M19.4043 30.0159L27.0899 36.383L38.8085 21.8298"
									stroke="#257654"
									stroke-width="3"
									stroke-linecap="square"
								/>
							</svg>
						</span>
					</div>
					<div className={styles.title}>{props.title}</div>
					<div className={styles.notes}>{props.notes}</div>

					{props.actions}
				</div>
			</Card>
		</div>
	)
}
