import clsx from 'clsx'
import React, { type FunctionComponent } from 'react'
import { useSharedLoading } from 'shared-loading-indicator'
import { LoadingBar } from './LoadingBar'
import styles from './SharedLoadingIndicator.module.sass'

export { SharedLoadingIndicatorContextProvider, useLocalLoading, useMirrorLoading } from 'shared-loading-indicator'

export const SharedLoadingIndicator: FunctionComponent = () => {
	const isLoading = useSharedLoading()

	return (
		<div className={clsx(styles.wrapper, isLoading && styles.is_loading)}>
			<div className={styles.in}>
				<LoadingBar />
			</div>
		</div>
	)
}
