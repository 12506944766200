const getProjectName = () => {
	const projectName = process.env.NEXT_PUBLIC_PROJECT_NAME
	if (projectName === 'goodlok') {
		return 'goodlok'
	}
	if (projectName === 'doubleshot') {
		return 'doubleshot'
	}
	return 'goodlok' // @TODO: remove this line when master .env.local is implemented
	throw new Error('Unknown project name ' + projectName)
}

export type ProjectName = ReturnType<typeof getProjectName>

export const projectName = getProjectName()
