/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Query:{
		signStatus:{

		},
		getProtectedAction:{

		}
	},
	UUID: "String",
	Mutation:{
		startSession:{
			device:"Device"
		},
		signIn:{
			device:"Device"
		},
		signInWithToken:{
			device:"Device"
		},
		signInWithService:{

		},
		signUpWithPassword:{

		},
		signUp:{

		},
		initAuthorization:{

		},
		signInWithAuthorizationKeys:{
			device:"Device"
		},
		confirmAuthorization:{

		},
		setDeletionRequest:{

		},
		initResetPassword:{

		},
		finishResetPassword:{

		}
	},
	Device:{

	},
	DateTime: "String"
}

export const ReturnTypes: Record<string,any> = {
	Query:{
		session:"SessionResponse",
		signStatus:"SignStatusResponse",
		getProtectedAction:"GetProtectedActionResponse"
	},
	SessionResponse:{
		type:"String",
		credentialsNote:"String",
		personId:"UUID",
		email:"String",
		userId:"UUID",
		customerId:"UUID",
		staffId:"UUID",
		roles:"String"
	},
	SignStatusResponse:{
		signedUp:"Boolean",
		hasCustomerProfile:"Boolean",
		verified:"Boolean",
		authenticatedWith:"String"
	},
	GetProtectedActionResponse:{
		ok:"Boolean",
		errorMessage:"String",
		type:"String",
		status:"String",
		used:"Boolean",
		expired:"Boolean"
	},
	Mutation:{
		startSession:"SignInResponse",
		signIn:"SignInResponse",
		signInWithToken:"SignInResponse",
		signInWithService:"SignInResponse",
		signUpWithPassword:"SignUpResponse",
		signUp:"SignUpResponse",
		signOut:"SignOutResponse",
		initAuthorization:"InitAuthorizationResponse",
		signInWithAuthorizationKeys:"SignInResponse",
		confirmAuthorization:"ConfirmAuthorizationResponse",
		setDeletionRequest:"DeletionRequestResponse",
		initResetPassword:"InitResetPasswordResponse",
		finishResetPassword:"FinishResetPasswordResponse"
	},
	SignInResponse:{
		ok:"Boolean",
		error:"SignInError",
		email:"String",
		userId:"UUID",
		personId:"UUID",
		customerId:"UUID",
		staffId:"UUID",
		roles:"String",
		token:"String"
	},
	SignInError:{
		code:"String",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignUpResponse:{
		ok:"Boolean",
		error:"SignUpError",
		email:"String",
		userId:"UUID",
		personId:"UUID",
		customerId:"UUID",
		staffId:"UUID",
		roles:"String"
	},
	SignUpError:{
		code:"String",
		developerMessage:"String",
		endUserMessage:"String"
	},
	SignOutResponse:{
		ok:"Boolean"
	},
	InitAuthorizationResponse:{
		ok:"Boolean",
		errorMessage:"String",
		publicKey:"String",
		secretKey:"String",
		expiresAt:"DateTime",
		url:"String"
	},
	ConfirmAuthorizationResponse:{
		ok:"Boolean",
		errorMessage:"String",
		confirmKey:"String"
	},
	DeletionRequestResponse:{
		ok:"Boolean",
		errorMessage:"String",
		message:"String",
		deletionRequestedAt:"DateTime",
		deletionExpectedAt:"DateTime"
	},
	InitResetPasswordResponse:{
		ok:"Boolean",
		errorMessage:"String",
		message:"String",
		publicKey:"String"
	},
	FinishResetPasswordResponse:{
		ok:"Boolean",
		errorMessage:"String",
		message:"String",
		continueUrl:"String"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}


export const HOST="Specify host"



const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, unknown> = {}) => {
  const fetchOptions = options[1] || {};
  if (fetchOptions.method && fetchOptions.method === 'GET') {
    return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  }
  return fetch(`${options[0]}`, {
    body: JSON.stringify({ query, variables }),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    ...fetchOptions,
  })
    .then(handleFetchResponse)
    .then((response: GraphQLResponse) => {
      if (response.errors) {
        throw new GraphQLError(response);
      }
      return response.data;
    });
};




export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};






export const InternalsBuildQuery = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  options?: OperationOptions,
) => {
  const ibb = (k: string, o: InputValueType | VType, p = '', root = true): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt(props, returns, ops, options?.variables?.values)(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const hasVariables = root && options?.variables?.$params ? `(${options.variables?.$params})` : '';
    const keyForDirectives = o.__directives ?? '';
    return `${k} ${keyForDirectives}${hasOperationName}${hasVariables}{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false))
      .join('\n')}}`;
  };
  return ibb;
};










export const Thunder = (fn: FetchFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation)(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullSubscriptionConstruct(fn)(operation)(o as any, ops) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: OperationOptions,
) => InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, ops)(operation, o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();

export const Gql = Chain(HOST);






export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o), options?.variables?.values);
};






export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (t: 'query' | 'mutation' | 'subscription') => (
  o: Record<any, any>,
  options?: OperationOptions,
) => {
  const builder = InternalsBuildQuery(AllTypesProps, ReturnTypes, Ops, options);
  return fn(builder(t, o));
};





export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | boolean
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string | undefined>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions<Z extends Record<string, unknown> = Record<string, unknown>> = {
  variables?: VariableInput<Z>;
  operationName?: string;
};

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;


export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');




const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (typeof propsP1 === 'boolean' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'object') {
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = (
  props: AllTypesPropsType,
  returns: ReturnTypesType,
  ops: Operations,
  variables?: Record<string, unknown>,
) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (a.startsWith('$') && variables?.[a.slice(1)]) {
        return a;
      }
      const checkType = ResolveFromPath(props, returns, ops)(p);
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};




export const resolverFor = <X, T extends keyof ValueTypes, Z extends keyof ValueTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;


export type SelectionFunction<V> = <T>(t: T | V) => T;
export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;




export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : Record<string, unknown>
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>[keyof MapType<SRC, R[P]>];
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};


export const useZeusVariables =
  <T extends Record<string, unknown>>(variables: T) =>
  <
    Z extends {
      [P in keyof T]: unknown;
    },
  >(
    values: Z,
  ) => {
    return {
      $params: Object.keys(variables)
        .map((k) => `$${k}: ${variables[k as keyof T]}`)
        .join(', '),
      $: <U extends keyof Z>(variable: U) => {
        return `$${String(variable)}` as unknown as Z[U];
      },
      values,
    };
  };

export type VariableInput<Z extends Record<string, unknown>> = {
  $params: ReturnType<ReturnType<typeof useZeusVariables>>['$params'];
  values: Z;
};


type ZEUS_INTERFACES = never
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["Query"]: AliasType<{
	session?:ValueTypes["SessionResponse"],
signStatus?: [{	username: string},ValueTypes["SignStatusResponse"]],
getProtectedAction?: [{	publicKey: string},ValueTypes["GetProtectedActionResponse"]],
		__typename?: boolean | `@${string}`
}>;
	["SessionResponse"]: AliasType<{
	type?:boolean | `@${string}`,
	credentialsNote?:boolean | `@${string}`,
	personId?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	customerId?:boolean | `@${string}`,
	staffId?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UUID"]:string;
	["SignStatusResponse"]: AliasType<{
	signedUp?:boolean | `@${string}`,
	hasCustomerProfile?:boolean | `@${string}`,
	verified?:boolean | `@${string}`,
	authenticatedWith?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GetProtectedActionResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	used?:boolean | `@${string}`,
	expired?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
startSession?: [{	sessionKey?: string | undefined | null,	device?: ValueTypes["Device"] | undefined | null},ValueTypes["SignInResponse"]],
signIn?: [{	username: string,	password: string,	device?: ValueTypes["Device"] | undefined | null},ValueTypes["SignInResponse"]],
signInWithToken?: [{	token: string,	device?: ValueTypes["Device"] | undefined | null},ValueTypes["SignInResponse"]],
signInWithService?: [{	service: string,	idToken: string,	context?: string | undefined | null},ValueTypes["SignInResponse"]],
signUpWithPassword?: [{	username: string,	password: string,	context?: string | undefined | null},ValueTypes["SignUpResponse"]],
signUp?: [{	username: string,	context?: string | undefined | null},ValueTypes["SignUpResponse"]],
	signOut?:ValueTypes["SignOutResponse"],
initAuthorization?: [{	context: string},ValueTypes["InitAuthorizationResponse"]],
signInWithAuthorizationKeys?: [{	context: string,	publicKey: string,	secretKey: string,	confirmKey: string,	device?: ValueTypes["Device"] | undefined | null},ValueTypes["SignInResponse"]],
confirmAuthorization?: [{	publicKey: string},ValueTypes["ConfirmAuthorizationResponse"]],
setDeletionRequest?: [{	deletionRequested: boolean},ValueTypes["DeletionRequestResponse"]],
initResetPassword?: [{	email: string},ValueTypes["InitResetPasswordResponse"]],
finishResetPassword?: [{	publicKey: string,	secretKey: string,	newPassword: string},ValueTypes["FinishResetPasswordResponse"]],
		__typename?: boolean | `@${string}`
}>;
	["SignInResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	error?:ValueTypes["SignInError"],
	email?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	personId?:boolean | `@${string}`,
	customerId?:boolean | `@${string}`,
	staffId?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignInError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Device"]: {
	platform: string,
	deviceId: string
};
	["SignUpResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	error?:ValueTypes["SignUpError"],
	email?:boolean | `@${string}`,
	userId?:boolean | `@${string}`,
	personId?:boolean | `@${string}`,
	customerId?:boolean | `@${string}`,
	staffId?:boolean | `@${string}`,
	roles?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignUpError"]: AliasType<{
	code?:boolean | `@${string}`,
	developerMessage?:boolean | `@${string}`,
	endUserMessage?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SignOutResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitAuthorizationResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	publicKey?:boolean | `@${string}`,
	secretKey?:boolean | `@${string}`,
	expiresAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DateTime"]:string;
	["ConfirmAuthorizationResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	confirmKey?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DeletionRequestResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	deletionRequestedAt?:boolean | `@${string}`,
	deletionExpectedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["InitResetPasswordResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	publicKey?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FinishResetPasswordResponse"]: AliasType<{
	ok?:boolean | `@${string}`,
	errorMessage?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
	continueUrl?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Query"]: {
		session?: GraphQLTypes["SessionResponse"] | undefined,
	signStatus: GraphQLTypes["SignStatusResponse"],
	getProtectedAction: GraphQLTypes["GetProtectedActionResponse"]
};
	["SessionResponse"]: {
		type?: string | undefined,
	credentialsNote?: string | undefined,
	personId?: GraphQLTypes["UUID"] | undefined,
	email?: string | undefined,
	userId?: GraphQLTypes["UUID"] | undefined,
	customerId?: GraphQLTypes["UUID"] | undefined,
	staffId?: GraphQLTypes["UUID"] | undefined,
	roles?: Array<string> | undefined
};
	["UUID"]:string;
	["SignStatusResponse"]: {
		signedUp: boolean,
	hasCustomerProfile: boolean,
	verified: boolean,
	authenticatedWith: Array<string>
};
	["GetProtectedActionResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	type?: string | undefined,
	status?: string | undefined,
	used?: boolean | undefined,
	expired?: boolean | undefined
};
	["Mutation"]: {
		startSession: GraphQLTypes["SignInResponse"],
	signIn: GraphQLTypes["SignInResponse"],
	signInWithToken: GraphQLTypes["SignInResponse"],
	signInWithService: GraphQLTypes["SignInResponse"],
	signUpWithPassword: GraphQLTypes["SignUpResponse"],
	signUp: GraphQLTypes["SignUpResponse"],
	signOut: GraphQLTypes["SignOutResponse"],
	initAuthorization: GraphQLTypes["InitAuthorizationResponse"],
	signInWithAuthorizationKeys: GraphQLTypes["SignInResponse"],
	confirmAuthorization: GraphQLTypes["ConfirmAuthorizationResponse"],
	setDeletionRequest: GraphQLTypes["DeletionRequestResponse"],
	initResetPassword: GraphQLTypes["InitResetPasswordResponse"],
	finishResetPassword: GraphQLTypes["FinishResetPasswordResponse"]
};
	["SignInResponse"]: {
		ok: boolean,
	error?: GraphQLTypes["SignInError"] | undefined,
	email?: string | undefined,
	userId?: GraphQLTypes["UUID"] | undefined,
	personId?: GraphQLTypes["UUID"] | undefined,
	customerId?: GraphQLTypes["UUID"] | undefined,
	staffId?: GraphQLTypes["UUID"] | undefined,
	roles?: Array<string> | undefined,
	token?: string | undefined
};
	["SignInError"]: {
		code: string,
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["Device"]: GraphQLTypes["Device"];
	["SignUpResponse"]: {
		ok: boolean,
	error?: GraphQLTypes["SignUpError"] | undefined,
	email?: string | undefined,
	userId?: GraphQLTypes["UUID"] | undefined,
	personId?: GraphQLTypes["UUID"] | undefined,
	customerId?: GraphQLTypes["UUID"] | undefined,
	staffId?: GraphQLTypes["UUID"] | undefined,
	roles?: Array<string> | undefined
};
	["SignUpError"]: {
		code: string,
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignOutResponse"]: {
		ok: boolean
};
	["InitAuthorizationResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	publicKey?: string | undefined,
	secretKey?: string | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined
};
	["DateTime"]:string;
	["ConfirmAuthorizationResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	confirmKey?: string | undefined
};
	["DeletionRequestResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	message?: string | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined
};
	["InitResetPasswordResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	message?: string | undefined,
	publicKey?: string | undefined
};
	["FinishResetPasswordResponse"]: {
		ok: boolean,
	errorMessage?: string | undefined,
	message?: string | undefined,
	continueUrl?: string | undefined
}
    }

export type GraphQLTypes = {
    ["Query"]: {
	__typename: "Query",
	session?: GraphQLTypes["SessionResponse"] | undefined,
	signStatus: GraphQLTypes["SignStatusResponse"],
	getProtectedAction: GraphQLTypes["GetProtectedActionResponse"]
};
	["SessionResponse"]: {
	__typename: "SessionResponse",
	type?: string | undefined,
	credentialsNote?: string | undefined,
	personId?: GraphQLTypes["UUID"] | undefined,
	email?: string | undefined,
	userId?: GraphQLTypes["UUID"] | undefined,
	customerId?: GraphQLTypes["UUID"] | undefined,
	staffId?: GraphQLTypes["UUID"] | undefined,
	roles?: Array<string> | undefined
};
	["UUID"]: string;
	["SignStatusResponse"]: {
	__typename: "SignStatusResponse",
	signedUp: boolean,
	hasCustomerProfile: boolean,
	verified: boolean,
	authenticatedWith: Array<string>
};
	["GetProtectedActionResponse"]: {
	__typename: "GetProtectedActionResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	type?: string | undefined,
	status?: string | undefined,
	used?: boolean | undefined,
	expired?: boolean | undefined
};
	["Mutation"]: {
	__typename: "Mutation",
	startSession: GraphQLTypes["SignInResponse"],
	signIn: GraphQLTypes["SignInResponse"],
	signInWithToken: GraphQLTypes["SignInResponse"],
	signInWithService: GraphQLTypes["SignInResponse"],
	signUpWithPassword: GraphQLTypes["SignUpResponse"],
	signUp: GraphQLTypes["SignUpResponse"],
	signOut: GraphQLTypes["SignOutResponse"],
	initAuthorization: GraphQLTypes["InitAuthorizationResponse"],
	signInWithAuthorizationKeys: GraphQLTypes["SignInResponse"],
	confirmAuthorization: GraphQLTypes["ConfirmAuthorizationResponse"],
	setDeletionRequest: GraphQLTypes["DeletionRequestResponse"],
	initResetPassword: GraphQLTypes["InitResetPasswordResponse"],
	finishResetPassword: GraphQLTypes["FinishResetPasswordResponse"]
};
	["SignInResponse"]: {
	__typename: "SignInResponse",
	ok: boolean,
	error?: GraphQLTypes["SignInError"] | undefined,
	email?: string | undefined,
	userId?: GraphQLTypes["UUID"] | undefined,
	personId?: GraphQLTypes["UUID"] | undefined,
	customerId?: GraphQLTypes["UUID"] | undefined,
	staffId?: GraphQLTypes["UUID"] | undefined,
	roles?: Array<string> | undefined,
	token?: string | undefined
};
	["SignInError"]: {
	__typename: "SignInError",
	code: string,
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["Device"]: {
		platform: string,
	deviceId: string
};
	["SignUpResponse"]: {
	__typename: "SignUpResponse",
	ok: boolean,
	error?: GraphQLTypes["SignUpError"] | undefined,
	email?: string | undefined,
	userId?: GraphQLTypes["UUID"] | undefined,
	personId?: GraphQLTypes["UUID"] | undefined,
	customerId?: GraphQLTypes["UUID"] | undefined,
	staffId?: GraphQLTypes["UUID"] | undefined,
	roles?: Array<string> | undefined
};
	["SignUpError"]: {
	__typename: "SignUpError",
	code: string,
	developerMessage: string,
	endUserMessage?: string | undefined
};
	["SignOutResponse"]: {
	__typename: "SignOutResponse",
	ok: boolean
};
	["InitAuthorizationResponse"]: {
	__typename: "InitAuthorizationResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	publicKey?: string | undefined,
	secretKey?: string | undefined,
	expiresAt?: GraphQLTypes["DateTime"] | undefined,
	url?: string | undefined
};
	["DateTime"]: string;
	["ConfirmAuthorizationResponse"]: {
	__typename: "ConfirmAuthorizationResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	confirmKey?: string | undefined
};
	["DeletionRequestResponse"]: {
	__typename: "DeletionRequestResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	message?: string | undefined,
	deletionRequestedAt?: GraphQLTypes["DateTime"] | undefined,
	deletionExpectedAt?: GraphQLTypes["DateTime"] | undefined
};
	["InitResetPasswordResponse"]: {
	__typename: "InitResetPasswordResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	message?: string | undefined,
	publicKey?: string | undefined
};
	["FinishResetPasswordResponse"]: {
	__typename: "FinishResetPasswordResponse",
	ok: boolean,
	errorMessage?: string | undefined,
	message?: string | undefined,
	continueUrl?: string | undefined
}
    }

export const createZeusVariables = useZeusVariables

export type FragmentResult<K extends keyof GraphQLTypes, Q extends unknown | ((...args: any[]) => unknown)> = InputType<
	GraphQLTypes[K],
	Q extends (...args: any[]) => unknown ? ReturnType<Q> : Q
>