import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import { Icon, IconName } from '../icons/Icon'
import styles from './AppSwitcherIcon.module.sass'
import { Squircle } from './Squircle'

export type IconNameOrVariants = IconName | { on: IconName; off: IconName }

export type AppSwitcherIconProps = {
	isActive?: boolean
	link?: string
	onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>
	children: React.ReactNode
	title?: string
	color?: string | null
	noHoverEffect?: boolean
	iconName?: IconNameOrVariants | null
}

export const AppSwitcherIcon: FunctionComponent<AppSwitcherIconProps> = ({
	isActive,
	onClick,
	children,
	title,
	link,
	color,
	noHoverEffect,
	iconName,
}) => {
	const squircle = (
		<Squircle className={styles.squircle}>
			<AppSwitcherIconVariant name={iconName} isActive={isActive} fallback={children} />
		</Squircle>
	)

	const toolTip = title ? <span className={styles.toolTip}>{title}</span> : null

	const content = link ? (
		<a href={link} onClick={onClick} className={styles.button}>
			{toolTip}
			{squircle}
		</a>
	) : onClick ? (
		<button type="button" onClick={onClick} className={styles.button}>
			{toolTip}
			{squircle}
		</button>
	) : (
		<span className={styles.button}>
			{toolTip}
			{squircle}
		</span>
	)

	return (
		<div
			className={clsx(styles.wrapper, isActive && styles.isActive, noHoverEffect && styles.noHoverEffect)}
			style={{ '--AppSwitcherIcon-color': color } as React.CSSProperties}
		>
			{content}
		</div>
	)
}

const AppSwitcherIconVariant: FunctionComponent<{
	name?: IconNameOrVariants | null
	isActive?: boolean
	fallback?: React.ReactNode
}> = ({ name, fallback }) => {
	if (name) {
		if (typeof name === 'string') {
			return (
				<span className={styles.icon}>
					<span className={styles.singleIcon}>
						<Icon name={name} />
					</span>
				</span>
			)
		} else {
			return (
				<span className={styles.icon}>
					<span className={styles.inactiveIcon}>
						<Icon name={name.off} />
					</span>
					<span className={styles.activeIcon}>
						<Icon name={name.on} />
					</span>
				</span>
			)
		}
	}

	return <span className={styles.name}>{fallback}</span>
}
