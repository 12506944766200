import type { FunctionComponent } from 'react'
import type { LogoProps } from '../Logo'

export const Logo: FunctionComponent<LogoProps> = ({ variant, ...otherProps }) => {
	return variant === 'small' ? (
		<svg
			width={'100%'}
			height={'100%'}
			viewBox="0 0 1024 1024"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...otherProps}
		>
			<path
				d="M298 766.164v232.352c0 5.694 4.749 9.484 9.499 9.484H756.781c4.749 0 7.599-2.85 7.599-7.59v-77.764c0-2.845-1.9-4.742-4.749-4.742-26.596-3.793-33.245-42.676-33.245-86.302V726.333c0-11.381 1.899-222.868 1.899-231.404 0-7.587-4.749-10.432-13.298-10.432H495.57c-4.749 0-10.448 1.897-10.448 10.432v68.283c0 6.639 3.799 11.381 9.498 11.381l137.73-.949c2.849 0 4.749 1.897 4.749 4.742 0 18.019-.95 81.56-.95 154.585v69.232c0 52.16 16.148 91.992 35.145 109.063 2.85 2.845.95 8.535-3.799 8.535l-124.432-1.897H392.986c-2.85 0-4.75-1.896-4.75-4.742V231.281c0-66.386 57.942-125.185 125.382-125.185s121.581 57.85 121.581 124.237v53.109c0 5.69 4.75 11.38 11.399 11.38h70.289c4.749 0 9.499-4.742 9.499-9.484v-53.109C726.386 124.115 634.25 16 514.567 16 393.936 16 298 123.166 298 232.229v533.935Z"
				fill="currentColor"
			/>
		</svg>
	) : (
		<svg xmlns="http://www.w3.org/2000/svg" width="144" height="38" viewBox="0 0 144 38" {...otherProps}>
			<path
				fill="currentColor"
				d="M136.817 11.514c-.992 0-1.873.162-2.646.486-.105 0-.171-.122-.101-.196 1.429-1.262 2.364-1.732 2.993-2.634L142.87.673c.13-.148 0-.293-.195-.293h-3.221c-.195 0-.454.148-.585.293l-8.048 13.74c-.05.088-.185.05-.185-.05L130.62.723a.336.336 0 0 0-.343-.343h-2.741a.286.286 0 0 0-.286.286V37c0 .122.128.25.286.25h2.791a.336.336 0 0 0 .343-.343l-.024-18.021h.024c0-2.492 2.149-4.688 4.688-4.688 2.539 0 4.543 1.271 4.543 5.666V37c0 .122.099.25.286.25H143c.143 0 .27-.128.27-.25V19.817c-.01-5.666-2.986-8.303-6.453-8.303z"
			/>
			<path
				fill="currentColor"
				d="M35.484 29.894c0 2.492-2.004 4.64-4.543 4.64-2.54 0-4.691-2.199-4.691-4.69V8.253c0-2.492 2.148-4.691 4.69-4.691 2.543 0 4.544 2.149 4.544 4.64zM30.94.19c-4.493 0-8.061 4.005-8.061 8.06v21.59c0 4.056 3.565 8.06 8.06 8.06 4.493 0 7.913-4.004 7.913-8.06V8.25c0-4.052-3.42-8.06-7.912-8.06z"
			/>
			<path
				fill="currentColor"
				d="M.26 28.233v8.672c0 .202.165.367.367.367H17.364a.283.283 0 0 0 .285-.272v-2.936c0-.064-.07-.162-.161-.175-.989-.141-1.255-1.587-1.255-3.212V26.77c0-.44.05-8.306.05-8.646 0-.293-.194-.39-.487-.39H7.638c-.195 0-.39.05-.39.39v2.539c0 .245.148.44.343.44l5.132-.047a.19.19 0 0 1 .191.192c-.01.676-.047 3.047-.047 5.752v2.605c0 1.953.616 3.395 1.295 4.059.124.117.034.336-.162.336l-4.6-.098H3.825a.188.188 0 0 1-.189-.188V8.254c0-2.492 2.15-4.691 4.691-4.691 2.543 0 4.544 2.149 4.544 4.64v1.991c0 .222.181.404.403.404h2.61a.357.357 0 0 0 .356-.357v-1.99C16.24 4.195 12.82.19 8.327.19 3.835.19.267 4.195.267 8.25v19.983z"
			/>
			<path
				fill="currentColor"
				d="M79.201 29.16c0 2.932-2.297 4.738-4.738 4.738H69.22c-.124 0-.165-.158-.064-.229.599-.423.764-1.321.764-1.725V6.202s.03-1.376-.757-1.927c-.098-.067-.04-.222.08-.222h5.22c2.441 0 4.738 1.806 4.738 4.738zM74.221.68h-7.385c-.158 0-.286.128-.286.32v35.918c0 .195.158.353.353.353h7.318c4.2 0 8.353-3.175 8.353-8.06V8.74C82.574 3.855 78.42.68 74.22.68z"
			/>
			<path
				fill="currentColor"
				d="M117.554 29.894c0 2.492-2.004 4.64-4.554 4.64-2.528 0-4.68-2.199-4.68-4.69V8.253c0-2.492 2.148-4.691 4.68-4.691 2.553 0 4.554 2.149 4.554 4.64zM113 .19c-4.482 0-8.05 4.005-8.05 8.06v21.59c0 4.056 3.565 8.06 8.05 8.06 4.503 0 7.923-4.004 7.923-8.06V8.25c0-4.052-3.42-8.06-7.923-8.06z"
			/>
			<path
				fill="currentColor"
				d="M57.324 19v10.894c0 2.492-2.004 4.64-4.543 4.64-2.54 0-4.688-2.199-4.688-4.69V8.253c0-2.492 2.149-4.691 4.691-4.691s4.543 2.149 4.543 4.64V19zM52.78.19c-4.493 0-8.061 4.005-8.061 8.06v21.59c0 4.056 3.565 8.06 8.06 8.06 4.493 0 7.913-4.004 7.913-8.06V8.25c.004-4.052-3.416-8.06-7.912-8.06z"
			/>
			<path
				fill="currentColor"
				d="M101.336 33.8l-10.233.088c-.171 0-.262-.215-.103-.33.833-.756 1.099-2.653 1.099-4.254L92.052 1c0-.196-.128-.32-.286-.32H89c-.146 0-.274.128-.274.32v30.215c0 1.55-.481 2.498-1.19 2.66a.274.274 0 0 0-.226.282V37c0 .147.128.274.286.274h13.744a.283.283 0 0 0 .286-.274v-2.907a.284.284 0 0 0-.29-.292z"
			/>
		</svg>
	)
}
