// Reexport all svg icons

export { default as appleLogo } from './appleLogo.svg'
export { default as applePayLogo } from './applePayLogo.svg'
export { default as arrowDown } from './arrowDown.svg'
export { default as arrowRight } from './arrowRight.svg'
export { default as backspace } from './backspace.svg'
export { default as blenderOff } from './blenderOff.svg'
export { default as blenderOn } from './blenderOn.svg'
export { default as bottleFreshBig } from './bottle-fresh-big.svg'
export { default as bottle } from './bottle.svg'
export { default as bottles } from './bottles.svg'
export { default as boxOpen } from './boxOpen.svg'
export { default as business } from './business.svg'
export { default as calendar } from './calendar.svg'
export { default as cartOff } from './cartOff.svg'
export { default as cartOn } from './cartOn.svg'
export { default as cashBill } from './cashBill.svg'
export { default as checkMark } from './checkMark.svg'
export { default as checkMarkThin } from './checkMarkThin.svg'
export { default as circleCancel } from './circleCancel.svg'
export { default as circleMinus } from './circleMinus.svg'
export { default as circlePlus } from './circlePlus.svg'
export { default as clock } from './clock.svg'
export { default as clockThin } from './clockThin.svg'
export { default as credit } from './credit.svg'
export { default as creditCard } from './creditCard.svg'
export { default as cross } from './cross.svg'
export { default as crossedNote } from './crossedNote.svg'
export { default as crossInCircle } from './crossInCircle.svg'
export { default as deliveryOff } from './deliveryOff.svg'
export { default as deliveryOn } from './deliveryOn.svg'
export { default as editPencil } from './editPencil.svg'
export { default as ellipsis } from './ellipsis.svg'
export { default as exit } from './exit.svg'
export { default as facebookLogo } from './facebookLogo.svg'
export { default as friendsOff } from './friendsOff.svg'
export { default as friendsOn } from './friendsOn.svg'
export { default as fruit } from './fruit.svg'
export { default as g } from './g.svg'
export { default as go } from './go.svg'
export { default as goodlokText } from './goodlok-text.svg'
export { default as googlePayLogo } from './googlePayLogo.svg'
export { default as groceriesOff } from './groceriesOff.svg'
export { default as groceriesOn } from './groceriesOn.svg'
export { default as gut } from './gut.svg'
export { default as gutFill } from './gutFill.svg'
export { default as list } from './list.svg'
export { default as locked } from './locked.svg'
export { default as marker } from './marker.svg'
export { default as minus } from './minus.svg'
export { default as note } from './note.svg'
export { default as pinOff } from './pinOff.svg'
export { default as pinOn } from './pinOn.svg'
export { default as plus } from './plus.svg'
export { default as posOff } from './posOff.svg'
export { default as posOn } from './posOn.svg'
export { default as print } from './print.svg'
export { default as productsOff } from './productsOff.svg'
export { default as productsOn } from './productsOn.svg'
export { default as receipt } from './receipt.svg'
export { default as recipe } from './recipe.svg'
export { default as schedule } from './schedule.svg'
export { default as settings } from './settings.svg'
export { default as shiftsOff } from './shiftsOff.svg'
export { default as shiftsOn } from './shiftsOn.svg'
export { default as statsOff } from './statsOff.svg'
export { default as statsOn } from './statsOn.svg'
export { default as swap } from './swap.svg' // @TODO: vybrat lepší grafiku, která připomíná prohození
export { default as team } from './team.svg'
export { default as unlocked } from './unlocked.svg'
export { default as wikiOff } from './wikiOff.svg'
export { default as wikiOn } from './wikiOn.svg'
