import { projectName } from '@goodlok/meta/projectName'
import dynamic from 'next/dynamic'
import type { FunctionComponent } from 'react'

const dynamicLogos = {
	goodlok: dynamic(() => import('./goodlok/Logo').then(module => module.Logo), {
		loading: () => <></>,
		ssr: false,
	}),
	doubleshot: dynamic(() => import('./doubleshot/Logo').then(module => module.Logo), {
		loading: () => <></>,
		ssr: false,
	}),
}

export type LogoProps = Partial<React.SVGProps<SVGSVGElement>> & {
	variant: 'small' | 'large'
}

export const Logo: FunctionComponent<LogoProps> = props => {
	const DynamicLogo = dynamicLogos[projectName]

	return <DynamicLogo {...props} />
}
