import { Collapsible } from 'collapsible-react-component'
import Image from 'next/image'
import type { ChangeEvent, FunctionComponent } from 'react'
import { useState } from 'react'
import { BoxTitle } from './BoxTitle'
import { Button } from './Button'
import { EditBox } from './EditBox'
import styles from './PhotoUpload.module.sass'

export type PhotoUploadProps = {
	title: string
	description?: string
	name: string
	removeButtonTitle?: string
	defalutValue?: string
	hideBorder?: boolean
}

export const PhotoUpload: FunctionComponent<PhotoUploadProps> = ({ ...props }) => {
	return <EditBox name={props.name} content={<PhotoUploadIn {...props} />} hideBorder={props.hideBorder} />
}

export const PhotoUploadIn: FunctionComponent<PhotoUploadProps> = ({
	title,
	description,
	name,
	removeButtonTitle,
	defalutValue,
}) => {
	const [imageUrl, setImageUrl] = useState<string | undefined>(defalutValue)

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		const file = event.target.files?.item(0)
		if (file) {
			setImageUrl(URL.createObjectURL(file))
		}
	}

	return (
		<div>
			<BoxTitle title={title} description={description} />
			<div className={styles.uploadWindow}>
				{!imageUrl && <div className={styles.uploadButton} />}
				<input
					className={styles.input}
					type="file"
					accept="image/*"
					name={name}
					onChange={event => handleChange(event)}
				/>
				{imageUrl && (
					<div className={styles.uploadedImg}>
						<Image src={imageUrl} alt="" layout="fill" objectFit="contain" />
					</div>
				)}
			</div>
			{removeButtonTitle && (
				<Collapsible open={Boolean(imageUrl)}>
					<div className={styles.secondaryActions}>
						<Button type="button" variant="seamless" onClick={() => setImageUrl(undefined)} disableGutters>
							<span className={styles.removeButton}>{removeButtonTitle}</span>
						</Button>
					</div>
				</Collapsible>
			)}
		</div>
	)
}
