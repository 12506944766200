import { getStage } from './getStage'
import type { ProjectName } from './projectName'
import { projectName } from './projectName'

type Projects = Readonly<{
	[key in ProjectName]: Readonly<{
		websiteUrl: string
		features: {
			// @TODO move configuration to stats project
			statsPages: {
				b2b: boolean
				products: boolean
				employees: boolean
			}
		}
	}>
}>

const getProjectConfig = () => {
	const stage = getStage()

	const projects: Projects = {
		goodlok: {
			websiteUrl: stage === 'beta' ? 'https://blog.gdlk.cz/' : 'https://goodlok.cz',
			features: {
				statsPages: { b2b: true, products: true, employees: true },
			},
		},
		doubleshot: {
			websiteUrl: 'https://www.doubleshot.cz/',
			features: {
				statsPages: { b2b: true, products: true, employees: true },
			},
		},
	} as const

	return projects[projectName]
}

export const projectConfig = getProjectConfig()
