import { FunctionComponent, useMemo } from 'react'
import { z } from 'zod'
import type { OnSuccessAuthAction } from '../../src'
import { Button } from '../components'
import { Icon } from '../icons/Icon'
import { useMobileAppSignal } from '../misc'
import { useGoodlokAppSupport } from '../misc/useGoodlokAppSupport'
import { useGoodlokAuth } from './hooks'
import styles from './IDPButton.module.sass'
import { SessionData, storeIDPSession } from './utils/idpSession'

const serviceColors = {
	facebook: '#4267B2',
	appleid: '#000000',
}

const serviceNames = {
	facebook: (
		<span className={styles.label}>
			<Icon name="facebookLogo" /> Přihlásit se přes Facebook
		</span>
	),
	appleid: (
		<span className={styles.label}>
			<Icon name="appleLogo" />
			Přihlásit se přes Apple
		</span>
	),
}

const IDPConfig = z.object({
	name: z.string().optional().nullable(),
	identityProvider: z.string(),
	redirectUrl: z.string().url(),
	color: z.string().optional().nullable(),
})

export type IDPButtonsProps = {
	successUrl?: string
	className?: string
	disabled?: boolean
	onSuccessAction?: OnSuccessAuthAction
}

export const IDPButtons: FunctionComponent<IDPButtonsProps> = ({
	successUrl,
	className,
	disabled,
	onSuccessAction,
}) => {
	const g = useGoodlokAuth()
	const support = useGoodlokAppSupport()

	const { send } = useMobileAppSignal()

	const buttons = useMemo(() => {
		const raw = z.array(IDPConfig).parse(JSON.parse(process.env.NEXT_PUBLIC_CONTEMBER_IDENTITY_PROVIDERS ?? '[]'))

		return raw.map(service => {
			return {
				service,
				onClick() {
					const serviceSlug = service.identityProvider as 'facebook' | 'appleid'
					send({ type: 'serviceAuth', payload: { service: serviceSlug } })

					const hasSupport = support?.serviceAuth?.includes(serviceSlug)

					if (!hasSupport) {
						const redirectUrl = service.redirectUrl
						const identityProvider = service.identityProvider
						g.zeus
							.tenant('mutation')({
								initSignInIDP: [
									{
										identityProvider,
										redirectUrl,
									},
									{
										ok: true,
										result: {
											authUrl: true,
											sessionData: true,
										},
									},
								],
							})
							.then(data => {
								const result = data.initSignInIDP?.result

								if (result) {
									storeIDPSession({
										redirectUrl,
										identityProvider,
										authUrl: result.authUrl,
										sessionData: SessionData.parse(result.sessionData),
										successUrl: successUrl,
										onSuccessAction,
									})

									window.location.href = result.authUrl
								}
							})
					}
				},
			}
		})
	}, [g.zeus, onSuccessAction, send, successUrl, support?.serviceAuth])

	if (buttons.length === 0) {
		return null
	}

	return (
		<div className={className}>
			{buttons.flatMap((button, i) => {
				const color =
					button.service.color ?? serviceColors[button.service.identityProvider as keyof typeof serviceColors] ?? null
				const name =
					button.service.name ?? serviceNames[button.service.identityProvider as keyof typeof serviceNames] ?? null
				return [
					i > 0 && ' ',
					<span key={i} className={styles.item}>
						<Button
							type="button"
							size="medium"
							variant="dark"
							round
							uppercase={false}
							bold={false}
							disabled={disabled}
							onClick={button.onClick}
							style={
								color
									? ({
											'--Button-base-color': color,
											'--Button-highlight-color': color,
									  } as React.CSSProperties)
									: undefined
							}
						>
							{name ?? button.service.identityProvider}
						</Button>
					</span>,
				]
			})}
		</div>
	)
}
