import clsx from 'clsx'
import React from 'react'
import styles from './StateSwitcher.module.sass'

export type StateSwitcherProps = Pick<
	React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
	'disabled'
> & {
	states: readonly {
		value: string
		label: string
		variant?: 'danger' | 'success'
	}[]
	value: string
	onChange: (value: string) => void
}

export const StateSwitcher: React.FunctionComponent<StateSwitcherProps> = ({
	states,
	value,
	onChange,
	...otherProps
}) => {
	const activeStateIndex = states.findIndex(state => state.value === value)

	if (activeStateIndex < 0) {
		console.error('StateSwitcher: activeState not found')
		return null
	}

	const activeState = states[activeStateIndex]

	return (
		<button
			className={clsx(styles.button, styles[`is_${activeState.variant}`])}
			onClick={() => {
				onChange(states[(activeStateIndex + 1) % states.length].value)
			}}
			{...otherProps}
		>
			{activeState.label}
		</button>
	)
}
