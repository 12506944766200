import { Icon } from '../icons/Icon'
import { useIsOnline } from '../misc/useIsOnline'
import { Card } from './Card'
import styles from './OfflineIndicator.module.sass'

export function OfflineIndicator() {
	const online = useIsOnline()

	if (online) {
		return null
	}

	return (
		<div className={styles.container}>
			<Card>
				<Icon name="locked" /> offline
			</Card>
		</div>
	)
}
