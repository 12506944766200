import { projectName } from '@goodlok/meta/projectName'
import { getStage } from '../../../../meta/getStage'
import { App, appsConfiguration } from './appsConfiguration'
import { getAppUrl } from './getAppUrl'

export const getEnabledApps = (): Array<
	Pick<App, 'name' | 'short' | 'handle' | 'pathname' | 'icon'> & { url: string }
> => {
	const stage = getStage()
	const apps = appsConfiguration.filter(app => stage !== 'production' || !app.hideInProduction)

	const projectApps = {
		goodlok: apps.map(app => ({
			name: app.name,
			short: app.short,
			handle: app.handle,
			pathname: app.pathname,
			icon: app.icon,
			url: getAppUrl(app, app.handle, stage === 'beta' ? 'gdlk.cz/' : 'goodlok.cz'),
		})),
		doubleshot: apps
			.filter(app => ['stats', 'timetables', 'panel'].includes(app.handle))
			.map(app => ({
				name: app.name,
				short: app.short,
				handle: app.handle,
				pathname: app.pathname,
				icon: app.icon,
				url: getAppUrl(
					app,
					app.handle === 'stats' ? 'statistiky' : app.handle === 'timetables' ? 'smeny' : app.handle,
					'doubleshot.cz',
				),
			})),
	}

	return projectApps[projectName]
}
