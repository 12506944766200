import type { IconNameOrVariants } from '../components'

export type App = {
	name: string
	short: string
	handle: string
	port: number
	icon?: IconNameOrVariants
	pathname?: string
	hideInProduction?: boolean
}
export const appsConfiguration: App[] = [
	{
		name: 'Produkty a sklad',
		short: 'Pro',
		handle: 'products',
		port: 3107,
		icon: { on: 'productsOn', off: 'productsOff' } as IconNameOrVariants,
	},
	{
		name: 'Objednávky',
		short: 'Obj',
		handle: 'orders',
		port: 3117,
		icon: { on: 'cartOn', off: 'cartOff' } as IconNameOrVariants,
	},
	{
		name: 'Pokladna',
		short: 'Reg',
		handle: 'pos',
		port: 3115,
		icon: { on: 'posOn', off: 'posOff' } as IconNameOrVariants,
	},
	{
		name: 'Výroba',
		short: 'Fre',
		handle: 'freshing',
		port: 3109,
		icon: { on: 'blenderOn', off: 'blenderOff' } as IconNameOrVariants,
	},
	{
		name: 'Delivery',
		short: 'Del',
		handle: 'delivery',
		port: 3105,
		icon: { on: 'deliveryOn', off: 'deliveryOff' } as IconNameOrVariants,
		pathname: '/admin',
	},
	// {
	// 	name: 'Groceries',
	// 	short: 'Gro',
	// 	handle: 'groceries',
	// 	port: 0,
	// 	icon: { on: 'groceriesOn', off: 'groceriesOff' } as IconNameOrVariants,
	// },
	{
		name: 'Směny',
		short: 'Shi',
		handle: 'timetables',
		port: 3116,
		icon: { on: 'shiftsOn', off: 'shiftsOff' } as IconNameOrVariants,
		pathname: '/admin',
	},
	// {
	// 	name: 'Friends',
	// 	short: 'Fri',
	// 	handle: 'friends',
	// 	port: 0,
	// 	icon: { on: 'friendsOn', off: 'friendsOff' } as IconNameOrVariants,
	// },
	{
		name: 'Statistiky',
		short: 'Sta',
		handle: 'stats',
		port: 3108,
		icon: { on: 'statsOn', off: 'statsOff' } as IconNameOrVariants,
	},
	{
		name: 'Wiki',
		short: 'Wik',
		handle: 'wiki',
		port: 3110,
		icon: { on: 'wikiOn', off: 'wikiOff' } as IconNameOrVariants,
	},
	// hide in production:
	{
		name: 'B2B',
		short: 'B2B',
		handle: 'b2b',
		port: 3102,
		hideInProduction: true,
	},
	{
		name: 'Buy',
		short: 'Buy',
		handle: 'buy',
		port: 3112,
		hideInProduction: true,
	},
	{
		name: 'Populate',
		short: 'Pop',
		handle: 'populate',
		port: 3106,
		hideInProduction: true,
	},
	{
		name: 'Styleguide',
		short: 'UI',
		handle: 'https://ui.goodlok.cz',
		port: 3104,
		hideInProduction: true,
	},
	{
		name: 'Nastavení',
		short: 'Nas',
		handle: 'panel',
		port: 3111,
		icon: { on: 'settings', off: 'settings' } as IconNameOrVariants,
	},
]
