import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { Button } from '../../components'
import styles from './AlreadySignedIn.module.sass'

export interface AlreadySignedInProps {
	goToSignInOrSignUp: () => void
	username: string
}

export const AlreadySignedIn: FunctionComponent<AlreadySignedInProps> = ({ goToSignInOrSignUp, username }) => {
	return (
		<>
			<h2 className={styles.title}>Chceš pokračovat jako Pavel Fuchs?</h2>
			<div className={styles.text}>{username}</div>
			<div className={styles.text}>
				Pokračováním souhlasíš s Podmínkami používání Goodlok s.r.o. a potvrzuješ, že máš přečtené naše{' '}
				<Link href="#@TODO" legacyBehavior>
					<a className={styles.link}>Zásady ochrany soukromí</a>
				</Link>
			</div>
			<div className={styles.buttons}>
				<Button variant="dark" size="large" round uppercase={false} bold={false} fullWidth type="button">
					Pokračovat
				</Button>
				<button className={styles.useDifferentAccount} type="button" onClick={() => goToSignInOrSignUp()}>
					Ne, použít jiný účet
				</button>
			</div>
		</>
	)
}
