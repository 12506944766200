import React, { FunctionComponent } from 'react'
import { Slide, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { assertNever } from '../utils/assertNever'

export const notify = (message: string, type: 'info' | 'success' | 'warning' | 'error' = 'info') => {
	if (type === 'info') {
		toast.info(message)
	} else if (type === 'success') {
		toast.success(message)
	} else if (type === 'warning') {
		toast.warning(message)
	} else if (type === 'error') {
		toast.error(message)
	} else {
		assertNever(type)
	}
}

export const Notifications: FunctionComponent = () => <ToastContainer position="bottom-right" transition={Slide} />
