"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTimeZone = exports.GoodlokTimetablesShiftUnassignReasons = exports.ProductAvailabilities = exports.GoodlokOrderStates = exports.GoodlokDeliveryTimelineTypes = exports.GoodlokDefaultRole = exports.GoodlokAnonymousSessionKeyVariableName = exports.GoodlokPersonIdVariableName = exports.GoodlokBackendEndpoints = exports.GoodlokChannels = exports.GoodlokRoles = exports.GoodlokUserRoles = exports.AnonymousRoles = exports.GoodlokBasicUserRoles = exports.GoodlokSystemRoles = void 0;
exports.GoodlokSystemRoles = ['public', 'point_of_sale', 'website', 'mobile_app'];
exports.GoodlokBasicUserRoles = [
    'staff',
    'customer',
    'shifts_manager',
    'picker',
    'shifts_worker',
    'cashier',
    'courier',
    'courier_manager',
    'stocks_manager',
];
exports.AnonymousRoles = ['anonymous'];
exports.GoodlokUserRoles = ['admin', ...exports.GoodlokBasicUserRoles, ...exports.AnonymousRoles];
exports.GoodlokRoles = [...exports.GoodlokSystemRoles, ...exports.GoodlokUserRoles, ...exports.AnonymousRoles];
exports.GoodlokChannels = ['b2b', 'eshop', 'app', 'pos', 'web', 'all', 'legacy']; // @TODO remove cash_desk
exports.GoodlokBackendEndpoints = ['auth', 'admin', 'orders'];
exports.GoodlokPersonIdVariableName = 'personId';
exports.GoodlokAnonymousSessionKeyVariableName = 'anonymousSessionKey';
exports.GoodlokDefaultRole = 'customer';
exports.GoodlokDeliveryTimelineTypes = ['asap', 'day', 'halfAnHour'];
exports.GoodlokOrderStates = [
    'created',
    'waitingForPayment',
    'processing',
    'dispatched',
    'fulfilled',
    'canceled',
];
exports.ProductAvailabilities = ['stockDependent', 'available', 'unavailable'];
exports.GoodlokTimetablesShiftUnassignReasons = ['sickness', 'vacation', 'other'];
exports.DefaultTimeZone = 'Europe/Prague';
