import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import styles from './OrderItemsOverview.module.sass'

export type OrderItemsOverviewItem = {
	id: string
	quantity: number
	product?: {
		id: string
		name: string
		image?: {
			url: string
			width?: number | undefined
			height?: number | undefined
			alt?: string | undefined
		}
	}
}

export type OrderItemsOverviewProps = {
	items: Array<OrderItemsOverviewItem>
	multiColumn?: boolean
}

export const OrderItemsOverview: FunctionComponent<OrderItemsOverviewProps> = ({ items, multiColumn = false }) => {
	return (
		<ul className={clsx(styles.wrapper, multiColumn && styles.is_multiColumn)}>
			{items.map(item => (
				<li className={styles.item} key={item.id}>
					<span className={styles.icon}>
						{item.product?.image && (
							<Image
								src={item.product.image.url}
								alt={item.product.image.alt ?? ''}
								layout="fill"
								objectFit="contain"
								sizes="18px"
							/>
						)}
					</span>{' '}
					<span className={styles.quantity}>{item.quantity}×</span>{' '}
					<span className={styles.name}>{item.product?.name}</span>
				</li>
			))}
		</ul>
	)
}
