import { useEffect, useMemo, useState } from 'react'
import { getEnabledApps } from './getEnabledApps'

export function useAppSwitcherPreset() {
	const apps = getEnabledApps()
	const [href, setHref] = useState('')

	useEffect(() => {
		setHref(window.location.href)
	}, [])

	const active = useMemo(() => {
		for (const app of apps) {
			const url = app.url
			if (href.startsWith(url)) {
				return app.handle
			}
		}
		return ''
	}, [apps, href])

	return {
		apps,
		active,
	}
}
