import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import styles from './Checkbox.module.sass'

export type CheckboxProps = {
	label: string
	onChange?: (checked: boolean) => void
	withOutline?: boolean
	name: string
	checkedValue: string
	checked?: boolean
	defaultChecked?: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({
	label,
	onChange,
	withOutline,
	name,
	checkedValue,
	defaultChecked,
	checked,
}) => {
	return (
		<label className={clsx(styles.tag, withOutline && styles.withOutline)}>
			<div className={styles.tagIn}>
				<input
					type="checkbox"
					className={styles.input}
					onChange={event => {
						if (onChange) {
							onChange(event.currentTarget.checked)
						}
					}}
					name={name}
					value={checkedValue}
					checked={checked}
					defaultChecked={defaultChecked}
				/>
				<div className={styles.customCheckbox} />
				<div className={styles.tagIn}>{label}</div>
			</div>
		</label>
	)
}
