import type { LinkProps } from 'next/link'
import type { FunctionComponent } from 'react'
import React from 'react'
import { Chip, type ChipProps } from './Chip'
import styles from './ChipNavigation.module.sass'

type Item = {
	readonly label: string
	readonly value: string
	readonly icon?: ChipProps['icon']
	readonly link?: LinkProps
}

export type ChipNavigationProps = {
	items: readonly Item[]
	value: string | undefined
	onChange?: (value: string) => void
}

export const ChipNavigation: FunctionComponent<ChipNavigationProps> = ({ items, value, onChange }) => {
	return (
		<div className={styles.wrapper}>
			{items.map((item, i) => {
				const commonProps = { active: value === item.value, icon: item.icon }
				const props: ChipProps =
					item.link === undefined
						? {
								...commonProps,
								type: 'button',
								onClick:
									onChange === undefined
										? undefined
										: () => {
												onChange(item.value)
										  },
						  }
						: {
								...commonProps,
								...item.link,
								type: 'link',
						  }
				return (
					<Chip key={i} {...props}>
						{item.label}
					</Chip>
				)
			})}
		</div>
	)
}
