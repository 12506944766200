import { projectName } from '@goodlok/meta/projectName'
import { Input } from '@goodlok/ui'
import type { InputProps } from '@goodlok/ui/src/components/Input'
import Link from 'next/link'
import { FunctionComponent, useRef } from 'react'
import { Button } from '../../components'
import { GoodlokAuthStatus } from '../GoodlokAuth'
import { GoodlokFormError } from '../GoodlokFormError'
import { IDPButtons } from '../IDPButtons'
import { useGoodlokAuth } from '../hooks'
import { useAttemptSignInWithCredentialManager } from '../useAttemptSignInWithCredentialManager'
import { useGoodlokAuthMutation } from '../useGoodlokAuthMutation'
import type { OnSuccessAuthAction } from '../utils/idpSession'
import styles from './SignInOrSignUp.module.sass'

export interface SignInOrSignUpProps {
	goToSignIn: () => void
	goToSignUp: () => void
	username: string
	setUsername: (username: string) => void
	successUrl?: string
	onSuccess?: (customer: { id: string }) => void
	onSuccessAction?: OnSuccessAuthAction
}

export const SignInOrSignUp: FunctionComponent<SignInOrSignUpProps> = ({
	goToSignIn,
	goToSignUp,
	username,
	setUsername,
	successUrl,
	onSuccess,
	onSuccessAction,
}) => {
	const formRef = useRef<HTMLFormElement>(null)
	const { signIn, signStatus } = useGoodlokAuthMutation()
	useAttemptSignInWithCredentialManager(onSuccess)
	const { status } = useGoodlokAuth()
	const isDisabled = status === GoodlokAuthStatus.Loading

	return (
		<>
			<h2 className={styles.title}>Zadáním e-mailu se zaregistruješ nebo přihlásíš</h2>
			<form
				ref={formRef}
				action=""
				method="POST"
				onSubmit={event => {
					event.preventDefault()
					signStatus.mutation.mutateAsync(username).then(data => {
						if (data.signedUp) {
							goToSignIn()
						} else {
							goToSignUp()
						}
					})
				}}
			>
				<fieldset disabled={signIn.mutation.isLoading}>
					<GoodlokFormError error={signIn.mutation.error} />
					<UsernameInput
						value={username}
						onChange={event => setUsername(event.target.value)}
						error={signIn.hasError('username')}
						readOnly={isDisabled}
					/>
					{/* @TODO load text from contember */}
					{projectName === 'goodlok' && (
						<p className={styles.text}>
							Pokračováním souhlasíš s Podmínkami používání Goodlok s.r.o. a potvrzuješ, že máš přečtené naše{' '}
							<Link href="#@TODO" legacyBehavior>
								<a className={styles.link}>Zásady ochrany soukromí</a>
							</Link>
						</p>
					)}
					<div className={styles.button}>
						<Button
							type="submit"
							size="large"
							variant="dark"
							fullWidth
							round
							uppercase={false}
							bold={false}
							disabled={username.length < 1 || isDisabled}
						>
							Pokračovat
						</Button>
					</div>
				</fieldset>
			</form>
			<IDPButtons
				successUrl={successUrl}
				onSuccessAction={onSuccessAction}
				className={styles.idpButtons}
				disabled={isDisabled}
			/>
		</>
	)
}

export const UsernameInput: FunctionComponent<
	Pick<InputProps, 'value' | 'onChange' | 'error' | 'disabled' | 'readOnly'> & {
		isAutoFillHelper?: boolean
	}
> = ({ value, onChange, error, disabled, readOnly, isAutoFillHelper = false }) => {
	const content = (
		<Input
			type="email"
			name="username"
			label="E-mail"
			labelPosition="inside"
			disabled={disabled}
			required
			autoFocus
			autoComplete="username email"
			enterKeyHint="next"
			value={value}
			onChange={onChange}
			error={error}
			readOnly={isAutoFillHelper || readOnly}
			tabIndex={isAutoFillHelper ? -1 : undefined}
		/>
	)
	if (isAutoFillHelper) {
		return <div className={styles.inputAutoFillHelper}>{content}</div>
	}
	return content
}
