import Link from 'next/link'
import { useCallback, useMemo } from 'react'
import { useAppSwitcherPreset } from '../misc'
import style from './EntityLink.module.sass'

const EntityRoutes = {
	Customer: {
		detail: '{orders}/detail/customer/[id]',
		invoices: '{panel}/customer/[id]',
		orders: '{orders}/orders/customer/[id]',
		buy: '{buy}/shop?customer=[id]',
	} as const,
	Order: {
		detail: '{panel}/detail/order/[id]',
	} as const,
	Invoice: {
		detail: '{panel}/detail/invoice/[id]',
	} as const,
	Cart: {
		detail: '{panel}/detail/invoice/[id]',
	} as const,
	Product: {
		detail: '{panel}/detail/invoice/[id]',
	} as const,
} as const

type EntityRoutes = typeof EntityRoutes

type EntityRouteEntityName = keyof typeof EntityRoutes

type EntityLinkProps<E extends EntityRouteEntityName> = {
	entity: E
	view?: keyof EntityRoutes[E]
	id: string | { id: string } | undefined
	children?: React.ReactNode
	onClick?: React.ComponentProps<'a'>['onClick']
	className?: string
	target?: React.ComponentProps<'a'>['target']
	rel?: React.ComponentProps<'a'>['rel']
	stopPropagation?: boolean
}

export function useEntityLink<E extends EntityRouteEntityName>(props: EntityLinkProps<E>) {
	const appSwitcherPreset = useAppSwitcherPreset()

	const e = EntityRoutes[props.entity]

	const route = (props.view ? props.view : Object.keys(e)[0]) as keyof typeof e

	const id = typeof props.id === 'object' && 'id' in props.id ? props.id.id : props.id

	return useMemo(() => {
		const urls = Object.entries(e).map(
			([key, value]) =>
				[
					key,
					value.replace('[id]', id ?? '#').replace(/^\{([a-z]+)\}/g, (original, handle) => {
						const app = appSwitcherPreset.apps.find(app => app.handle === handle)
						return app ? app.url.replace(/\/$/g, '') : original
					}),
				] as const,
		)

		const url = urls.find(([key]) => key === route)?.[1]

		return { id, urls: urls.filter(([key]) => key !== route), url }
	}, [appSwitcherPreset.apps, e, id, route])
}

export function EntityLink<E extends EntityRouteEntityName>(props: EntityLinkProps<E>) {
	const { id, url, urls } = useEntityLink(props)

	const restUrls = urls

	const caption = props.children ?? `${props.entity}#${id}`

	const onClick = useCallback(
		(e: React.MouseEvent<HTMLAnchorElement>) => {
			if (props.stopPropagation) {
				e.stopPropagation()
			}
			return props.onClick?.(e)
		},
		[props],
	)

	const aProps = {
		onClick: props.onClick || props.stopPropagation ? onClick : undefined,
		className: props.className ?? style.link,
		target: props.target,
		rel: props.rel,
	}

	return (
		<>
			<Link href={url ?? '#'} legacyBehavior>
				<a {...aProps}>{caption}</a>
			</Link>
			{!props.view && (
				<sup>
					{restUrls.flatMap(([key, val], i) => [
						i > 0 && ', ',
						<Link key={key} href={val} legacyBehavior>
							<a {...aProps}>{key}</a>
						</Link>,
					])}
				</sup>
			)}
		</>
	)
}
